import { workExperience } from '../data/workExperience';
import CardExperience from '../components/cards/CardExperience';
import React from "react";
import "./work-experience.css";

function WorkExperience() {

    const generatedWorkExperienceList = () => {
        return workExperience.map((item) => {
            return <CardExperience key={item.position} workExperience={item} />
        })
    }
    return (
        <div id="work-experience" className="content-section">
            <h3 className="link-title">Experience</h3>

            {generatedWorkExperienceList()}
        </div>
    );
}

export default WorkExperience;
