import React from "react";
import "./skills.css";

function Skills() {

    return (
        <div id="skills" className="content-section">
            <h3 className="link-title">Skills</h3>

            <div className="grid-container skill-item-container">
                <div className="grid skill-box">
                    <h5>Programming</h5>
                    <ul>
                        <li>React</li>
                        <li>JavaScript (ES6)</li>
                        <li>TypeScript</li>
                        <li>HTML</li>
                        <li>CSS/Sass</li>
                        <li>Git</li>
                        <li>Node</li>
                        <li>Wordpress</li>

                    </ul>
                </div>
                <div className="grid skill-box">
                    <h5>Design</h5>
                    <ul>
                        <li>Figma</li>
                        <li>Sketch</li>
                        <li>Illustrator</li>
                        <li>Prototyping</li>
                        <li>Wire-framing</li>
                        <li>User Testing</li>
                    </ul>
                </div>
            </div>


        </div>

    );
}

export default Skills;
