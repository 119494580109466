import './cardPortfolio.css';
import React from "react";
import { Link } from 'react-router-dom';

function LinkContainer({ projectPath, url, children }) {
    if (projectPath) {
        return (
                <Link className="link-container" to={`/project/${projectPath}`}>
                    {children}
                </Link>
        );
    } else if (url) {
        return (
            <a className="link-container" target="_blank" href={url} rel="noreferrer">
                {children}
            </a>
        );
    } else {
        return (
            <div>
                {children}
            </div>
        );
    }
};

function CardPortfolio({ portfolio }) {
    const {projectPath, project, url, companyName, about, image, altLogo, technologyList } = portfolio;


    const generatedList = () => {
        return technologyList.map((item) => {
            return <span key={item} className="pill">{item}</span>
        })
    }
    return (
    <React.Fragment>
        <LinkContainer url={url} projectPath={projectPath}>
            <div id="portfolioCard">
                <img src={image} alt={altLogo} className="project-image"/>

                <div className="portfolio-content1">
                    <h5>{project}</h5>
                    <h6>{companyName}</h6>
                    <p className="about">{about}</p>

                    {generatedList()}
                </div>

            </div>
        </LinkContainer>
    </React.Fragment>

    );
}

export default CardPortfolio;
