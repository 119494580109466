import React from "react";
import './App.css';
import { Route, Routes } from "react-router-dom";
import CampaignEditor from "./portfolio/CampaignEditor";
import Home from "./Home.jsx";

function App() {
  return (
    <div className="App">
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="project/campaign-editor" element={<CampaignEditor />} />
        </Routes>

    </div>
  );
}

export default App;
