import React from "react";
import "./footer.css";

function Footer() {
    return (
        <div id="footer" className="content-section">
            <div className="grid skill-box">
                <h6>Thanks for visiting my site!</h6>
                <p>This site was designed using Figma and built with React.js. All CSS was written without the use of a pre-existing library.</p>
            </div>
        </div>

    );
}

export default Footer;
