import React from "react";
import "./about.css";

function About() {
    return (
        <div id="about" className="content-section">
            <h4>Design / Engineering / UX Advocate</h4>
            <p>I love finding the intersection between elegant code and intuitive user experience. I believe you can have the most cutting edge technology, but if the user isn't considered when building an app, then what good is the technology. I have education in both design and software engineering. On teams I advocate for the user and making their life easier. My knowledge of both engineering principles and design allow me to build beautiful apps from design inception to final engineered product.</p>
        </div>
    )
}

export default About;
