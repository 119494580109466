import React from "react";
import "./project-page.css";
import SectionBefore from "./images/section-before.png";
import SectionAfter from "./images/section-after.png";
import ThemeBefore from "./images/theme-before.jpg";
import ThemeAfter from "./images/theme-after.png";

import PageContainer from "../PageContainer";

function CampaignEditor() {
    return (
        <PageContainer>

            <div id="projectPage" className="content-section">
                <div className="project-header-content">


                    <div className="link-title">Project / Campaign Site Editor</div>
                    <h4>The campaign site editor is a Squarespace-like website builder geared for non-profit
                        fundraising.
                        This project took 6 months to build, resulting in a 40% increase in online donations for
                        customers.
                    </h4>
                    <p>I was one of two developers on this project. I took ownership of the design and user experience
                        while also building a large portion of the widgets and functionality of the site editor. The
                        result provided users with an app to make live changes to the theming, content, and section
                        inputs they were creating.</p>

                </div>

                <div className="card project-details">
                    <h5>Live Section Creation</h5>
                    <div className="iframe-container">
                        <iframe className="responsive-iframe"
                                src="https://www.youtube.com/embed/NbozMEQhsaw?si=t9DvW2WwSPhODnV0"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen></iframe>


                    </div>
                    <h6>Video taken from Funraise's Youtube channel</h6>

                </div>
                <div className="card project-details">
                    <h5>Initial Mockups</h5>

                    <div className="project-image-grid">
                        <div>
                            <img className="fifty-percent" src={SectionBefore} alt="Section Mockup Before"/>
                            <h6>BEFORE</h6>
                        </div>

                        <div>
                            <img className="fifty-percent" src={SectionAfter} alt="Section Mockup After"/>
                            <h6>AFTER</h6>
                        </div>

                    </div>

                    <p>My team delivered mockups of what the editor might look like. Most of the mockups were in rough
                        draft form and didn’t showcase every possible layout. I was trusted with finishing incomplete
                        designs and freshening up initial mockups to ensure desired functionality as well as providing
                        an intuitive user experience.</p>


                </div>

                <div className="card project-details">
                    <h5>Challenges</h5>

                    <p>The first limitation we had on the project was the lack of flexibility in the API structure. The
                        API’s were already built and being utilized on a previous version of the app. This meant any new
                        changes we wanted to make to the project still had to follow the current API structure.</p>

                    <div className="project-image-grid">
                        <div>
                            <img className="fifty-percent" src={ThemeBefore} alt="Theme Mockup Before"/>
                            <h6>BEFORE</h6>
                        </div>

                        <div>
                            <img className="fifty-percent" src={ThemeAfter} alt="Theme Mockup After"/>
                            <h6>AFTER</h6>
                        </div>

                    </div>
                    <p>Theming became a challenge. We wanted to provide the user with complete functionality of the
                        site. We realized that giving them control over every conceivable color field would become
                        overwhelming not just from a development perspective but also for the user to have to manage.
                        The solution I advocated for which we ultimately went with was to provide the user with three
                        different color themes. Each theme had general color fields like headline, body text, button
                        colors, and background color. Then the user could quickly select a theme for each widget.</p>
                </div>

                <div className="card project-details">

                    <h5>Theming</h5>
                    <div className="iframe-container">
                        <iframe className="responsive-iframe"
                                src="https://www.youtube.com/embed/3ZGjHHX8xwk?si=zu13GBN56Ak-qjEf&amp;start=172"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen></iframe>

                    </div>

                    <h6>Video taken from Funraise's Youtube channel</h6>

                </div>


            </div>
        </PageContainer>

    );
}

export default CampaignEditor;
