import funraiseLogo from '../images/funraise_logo.png';
import linkLogo from '../images/link_logo.png';

export const workExperience = [
    {
        companyName: 'Funraise',
        position: 'UI Staff Software Engineer',
        image: funraiseLogo,
        altText: 'Funraise Logo',
        url: 'https://funraise.org',
        listItems: [
           'Founding member at Funraise. Created the first version of our Saas UI, resulting in $1M ARR in the first year.',
            'Collaborated and engineered a company-wide library of 100+ UI components, writing 40% of new UI components improving velocity and scale of the front-end development team.',
            'Created a custom Squarespace-like website builder in 6 months, resulting in a 40% increase in online donations for customers.',
            'Designed and engineered an auctions feature and management system. Allowing users to manage bids, create/edit auction item and purchase auction items for bidder.',
            'Independently engineered over 20 projects as the sole front-end engineer. Creating designs, prototyping to final coded implementation.',
            'Led UI side of 20+ projects, making major design decisions affecting the user experience and feature behavior.',

        ]
    },
    {
        companyName: 'Liberty in North Korea',
        position: 'Web Manager / Graphic Designer',
        image: linkLogo,
        altText: 'Liberty in North Korea Logo',
        url: 'https://libertyinnorthkorea.org',
        listItems: [
            'Sole developer responsible for the organization’s website,  maintaining content, stability, and fundraising presence.',

            'Built WordPress templates to enable 6 departments to input their information into the website.',

            'Rebuilt website from the ground up to render properly on phones as well as desktop.',

            'Designed and engineered custom campaign sites on 6 major fundraising campaigns, helping the organization reach its yearly financial goals.',

            'Collaborated with the creative director as a team of two on rebranding the organization.',

            'Mentored and managed design and engineering interns.',

        ]
    }
];