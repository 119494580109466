import React from "react";
import Navigation from "./navigation/Navigation";
import {useLocation} from "react-router-dom";

function PageContainer({ children }) {
    const { pathname } = useLocation();

    return (

        <div className="App-header">
            <div className="sb12"></div>

            <div className={`App-header-left ${pathname && pathname.includes('project')  ? '' : 'slideIn'}`}>
                <Navigation/>
            </div>

            <div className="App-header-right">
                <div className="container-two">
                    {children}
                </div>
            </div>
        </div>

    )
}

export default PageContainer;
