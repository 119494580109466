import ScrollToLink from "../components/scroll-to-link/ScrollToLink";
import Contact from "../contact/contact";
import React from "react";
import { useLocation, Link } from "react-router-dom";

function Navigation() {
    const { pathname } = useLocation();

    return (
        <React.Fragment>
            <div id="navigation" className="container">
                <h2>Hello, my name is</h2>
                <h1>Nico Leonard</h1>



                {pathname && pathname.includes('project') ?
                    <div>
                        <Link id="link" className="link-title back-home" to="/">Back to Profile</Link>
                    </div>
                    :
                    <React.Fragment>
                        <h4>I am a front-end engineer with a background in design. I have been
                            developing & designing websites for over 10 years.</h4>
                        <ScrollToLink name="Projects" link="portfolio"/>
                        <ScrollToLink name="Experience" link="work-experience"/>
                        <ScrollToLink name="Skills" link="skills"/>

                        <div className="mobile-contact">
                            <Contact/>
                        </div>
                    </React.Fragment>

                }


            </div>
            <div className="desktop-contact">
                <Contact/>
            </div>
        </React.Fragment>
    )
}

export default Navigation;
