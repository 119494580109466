import './cardExperience.css';
import React from "react";

function CardExperience({ workExperience }) {
    const {url, position, companyName, listItems, image, altText } = workExperience;

    const generatedList = () => {
       return listItems.map((item, index) => {
           return <li key={index}>{item}</li>
        })
    }

    return (
        <div id="companyCard">
            <img src={image} alt={altText} />
            <div className="card">
                <h5>{position}</h5>
                <a href={url} target="_blank" rel="noreferrer">{companyName}</a>
                <ul>
                    {generatedList(listItems)}
                </ul>
            </div>
        </div>

    );
}

export default CardExperience;
