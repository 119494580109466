import React from "react";

import About from "./about/about";
import WorkExperience from "./work-experience/WorkExperience";
import Portfolio from "./portfolio/Portfolio";
import Skills from "./skills/Skills";
import Footer from "./footer/Footer";
import PageContainer from "./PageContainer";

function Home() {
    return (
        <PageContainer>


        <div>
            <About />
            <Portfolio />
            <WorkExperience />
            <Skills />
            <Footer />
        </div>

        </PageContainer>

    );
}

export default Home;
