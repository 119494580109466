import React from "react";
import "./portfolio.css";
import {portfolio} from "../data/portfolio";
import CardPortfolio from "../components/cards/CardPortfolio";

function Portfolio() {

    const generatedWorkExperienceList = () => {
        return portfolio.map((item) => {
            return <CardPortfolio key={item.project} portfolio={item} />
        })
    }
    return (
        <div id="portfolio" className="content-section">
            <h3 className="link-title">Projects</h3>
            {generatedWorkExperienceList()}
        </div>

    );
}

export default Portfolio;
