import campaignEditor from '../images/campaign-editor2.jpeg';
import mannafy from '../images/mannafy.jpeg';
import givingForm from '../images/giving-form.webp';

export const portfolio = [
    {
        companyName: 'Funraise',
        project: 'Campaign Site Editor',
        image: campaignEditor,
        altText: 'Funraise Campaign Editor',
        projectPath: 'campaign-editor',
        about: 'Created a custom Squarespace-like website builder in 6 months, resulting in a 40% increase in online donations for customers.',
        technologyList: [
            'React',
            'Javascript',
            'Figma',
            'Layout Design',
            'Node',
        ],
    },

    {
        companyName: 'Funraise',
        project: 'Giving Form',
        image: givingForm,
        altText: 'Funraise Giving Form',
        url: 'https://www.funraise.org/features/donation-forms',
        about: 'Consulted on design and helped build the giving form components and giving form editor.',
        technologyList: [
            'React',
            'Javascript',
            'Typography',
            'Node',
        ],
    },

    {
        companyName: 'Mannafy',
        project: 'App Design Consulting',
        image: mannafy,
        about: 'Created the initial user experience for the Mannafy mobile app.',
        technologyList: [
            'Figma',
            'Layout Design',
        ],
    },
    {
        companyName: 'Funraise',
        project: 'Auctions',
        // altText: 'Funraise Logo',
        // url: 'https://funraise.org',
        about: 'Designed the user experience for an online auctions app. Engineered the auctions management tool to CRUD auction items and bidder checkout. Images not yet available. Project has been announced but not yet released.',
        technologyList: [
            'React',
            'Javascript',
            'Figma',
            'Layout Design',
            'Node',
        ],
    },
];