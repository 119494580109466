import React from "react";
import { Link } from "react-scroll";

function ScrollToLink({link, name}) {

    console.log(window.location.pathname);

    return (
        <div>
            <Link
                activeClass="active"
                to={link}
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
            >
                <span id="link" className="link-title" >{name}</span>
            </Link>
        </div>

    );
}

export default ScrollToLink;
