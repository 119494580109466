import github from "../images/github.svg";
import email from "../images/email.svg";
import linkedIn from "../images/linkedin1.svg";
import profile from "../images/profile.png";
import React, {useState} from "react";
import "./contact.css";

function Contact() {
    const [copied, setCopied] = useState();
    const copyToClipboard = () => {
        navigator.clipboard.writeText('me@nicoleonard.xyz');
        setCopied('Email copied to clipboard.')
    }
    return (
        <div id="contact" className="content-container">
            <img className="profile-image" src={profile} alt="profile"/>

            <div className="contact-info">
                <h3>Let's Chat:</h3>
                <button className="tooltip" onClick={copyToClipboard}>
                    {!copied && <span className="tooltiptext">Copy my email to your clipboard.</span>}

                    <img className="logo1" src={email} alt="Email"/>
                </button>
                <a href="https://www.linkedin.com/in/nicholas-leonard-6a20003a/" target="_blank" rel="noreferrer"><img className="logo1" src={linkedIn} alt="linkedIn"/></a>
                <a href="https://github.com/nleonard0112" target="_blank" rel="noreferrer"><img className="logo1" src={github} alt="github" /></a>
                {copied && <h6><span>&#10003;</span>{copied}</h6>}
            </div>
        </div>
    )
}

export default Contact;
